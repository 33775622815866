import { CarriersService } from "@/services/CarriersService.js";

export default {
  data() {
    return {
      carriersService: new CarriersService({
        http: this.$http,
        auth: this.$auth,
        store: this.$store,
      }),
    };
  },
  computed: {
    carrierTypes() {
      return this.carriersService?.data?.CarrierTypes;
    },
    carriers() {
      return this.carriersService?.data?.Carrier;
    },
    relayPoint: {
      get() {
        return this.$store.state.carriers.RelayPoint;
      },
      set(value) {
        this.$store.commit("carriers/setRelayPoint", value);
      },
    },
  },
  methods: {},
};
